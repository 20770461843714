// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-page-js": () => import("./../../../src/Templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-archive-page-js": () => import("./../../../src/Templates/ArchivePage.js" /* webpackChunkName: "component---src-templates-archive-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/Templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-category-list-page-js": () => import("./../../../src/Templates/CategoryListPage.js" /* webpackChunkName: "component---src-templates-category-list-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/Templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/Templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-flow-page-js": () => import("./../../../src/Templates/FlowPage.js" /* webpackChunkName: "component---src-templates-flow-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/Templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-order-detail-page-js": () => import("./../../../src/Templates/OrderDetailPage.js" /* webpackChunkName: "component---src-templates-order-detail-page-js" */),
  "component---src-templates-profile-page-js": () => import("./../../../src/Templates/ProfilePage.js" /* webpackChunkName: "component---src-templates-profile-page-js" */),
  "component---src-templates-voice-page-js": () => import("./../../../src/Templates/VoicePage.js" /* webpackChunkName: "component---src-templates-voice-page-js" */)
}

